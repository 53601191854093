import Alpine from 'alpinejs'

export default (function () {
  Alpine.directive('tooltip', async (el, { expression }, { effect }) => {
    const [tippy] = await Promise.all([
      import('tippy.js').then((d) => d.default),
      import('tippy.js/dist/tippy.css'),
    ])

    effect(() => {
      tippy(el, {
        allowHTML: true,
        maxWidth: 240,
        content: `<div class="py-2 px-3 min-h-[--tooltip-h]">${expression}</div>`,
      })
    })
  })
})()
