import Alpine from 'alpinejs'

export default (function () {
  Alpine.data(
    'newsTabs',
    function ({ active, allowedIds } = { active: null, allowedIds: [] }) {
      return {
        active,
        allowedIds,
        checkActive() {
          const hash = window.location.hash.substring(1)
          if (this.allowedIds.indexOf(hash) > -1) this.active = hash
        },
        scrollParent($el, condition) {
          if (condition) {
            $el.parentElement.scrollLeft = $el.offsetLeft
          }
        },
      }
    }
  )
})()
