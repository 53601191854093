import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('header', function () {
    return {
      scrolling: false,
      openMenu: false,
      openSearch: false,
      openDrawer: false,
      toggle: {
        ['@click.prevent']() { this.openMenu = !this.openMenu },
      },
      overlay: {
        [':class']: function() {
          return {
            'max-md:opacity-0 max-md:translate-x-1/2 max-md:pointer-events-none': !this.openMenu
          }
        },
      },
      menu: {
        [':class']: function() {
          return {
            '-translate-y-full opacity-0 pointer-events-none h-0': this.openSearch
          }
        },
      },
      search: {
        [':class']: function() {
          return {
            'translate-y-full opacity-0 pointer-events-none h-0': !this.openSearch
          }
        },
        '@click.outside': function() {
          this.openSearch = false
        },
      },
      init() {
        window.addEventListener('scroll', () => {
          if (window.scrollY > 0 && !this.scrolling) {
            this.scrolling = true
          }
          if (window.scrollY <= 0 && this.scrolling) {
            this.scrolling = false
          }
        })
      }
    }
  })
})()
