import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('parallax', function (factor = 1) {
    return {
      offset: 0,
      init() {
        const el = this.$el
        this.calc()

        window.addEventListener('scroll', () => {
          const scrollY = window.scrollY || window.pageYOffset
          const diffY = scrollY - this.offset

          el.style.transform =
            'translateY(' + -diffY * (factor / 10) + 'px)'
        })

        try {
          new ResizeObserver(() => {
            this.calc()
          }).observe(el)
        } catch (error) {
          // silence
        }
      },
      calc() {
        this.offset = window.scrollY + this.$el.getBoundingClientRect().top
      }
    }
  })
})()
