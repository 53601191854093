import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('accordion', function (open = false) {
    return {
      open,
      toggle: {
        ['@click.prevent']() { this.open = !this.open },
      },
      body: {
        [':class']: function() {
          return {
            'grid-rows-0fr': !this.open,
            'grid-rows-1fr': this.open,
          }
        },
      },
      arrow: {
        [':class']: function() {
          return {
            'rotate-180': this.open,
          }
        },
      },
    }
  })
})()
