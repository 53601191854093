import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('nav', function () {
    return {
      openNav: false,
      current: null,
      toggleNav(elem) {
        this.openNav = !this.openNav
        if (this.openNav) this.current = elem
        else this.current = null
      },
    }
  })
})()