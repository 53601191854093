import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'


import 'modules/measure'
import 'modules/parallax-bg'
import 'modules/accordion'
import 'modules/tabs'
import 'modules/tooltip'

import 'components/header'
import 'components/nav'
import 'components/news-tabs'

Alpine.store('app', {
  toggleOverflow(flag = false, responsive = false) {
    if (responsive) document.documentElement.classList.toggle('max-md:overflow-hidden', flag)
    else document.documentElement.style.overflow = flag ? null : 'hidden'
  },
})

Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()
