import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('tabs', function (active = 0) {
    let targetCache = null // needed for safari

    return {
      instance: null,
      active,
      scrollTo($el) {
        if (!this.$refs.toggleWrap) return

        const left = $el.offsetLeft + $el.offsetWidth / 2 - this.$refs.toggleWrap.offsetWidth / 2
        try {
          this.$refs.toggleWrap.scrollTo({ left, behavior: 'smooth' })
        } catch (error) {
          this.$refs.toggleWrap.scrollLeft = left
        }
        // $el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
      },
      getCurrentIndex() {
        const content = this.$refs.content
        const activeChild = targetCache || content.querySelector(window.location.hash)

        if (!activeChild) return false

        targetCache = null
        return [...content.children].indexOf(activeChild)
      },
      handleClick(e) {
        const target = (e.target.closest('a') || e.target.parentElement.querySelector('a')).hash
        const elem = document.querySelector(target)
        const id = elem?.id
        targetCache = elem

        if (!elem) return

        elem.removeAttribute('id')
        this.$nextTick(() => {
          elem.setAttribute('id', id)
        })
      },
      handleHash() {
        if (window.location.hash.length <= 1) return
        
        const index = this.getCurrentIndex()
        if (typeof index === 'number') this.active = index
      },
      init() {
        this.handleHash()
        window.addEventListener('hashchange', this.handleHash.bind(this))
      },
    }
  })
})()